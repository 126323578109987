<script>
import Base from '#/v-shop/vue-src/v-shop/dynamic-pages-components/comp_dpc-EnhancedProductsCarousel.vue'
export default {
	extends: Base,
	computed: {
		carouselConfig() {
			if (this.$b.d) {
				if (this.imgBoard) {
					// desktop with image
					let img_w = this.data.img.data.width
					if (img_w <= 300) {
						return { slidesPerPage: 3, gutter: 20, peekRight: 0 }
					} else if (img_w <= 360) {
						return { slidesPerPage: 2, gutter: 20, peekRight: 220 }
					} else {
						return { slidesPerPage: 2 }
					}
				} else if (this.productBoard || this.bgBoard) {
					// desktop with product or color
					return { slidesPerPage: 3, gutter: 20, peekRight: 0 }
				} else {
					// desktop without image
					return { slidesPerPage: 4 }
				}
			} else if (this.$b.t) {
				// tablet
				return { slidesPerPage: 3 }
			} else {
				// mobile
				return { slidesPerPage: 2, gutter: 10 }
			}
		},
	},
}
</script>
